@import "../../../styles/breakpoints.module";

.moonWrapper {
  width: 300px;
  height: 400px;
  position: relative;
  z-index: 2;
  pointer-events: none;

  @media screen and (max-width: $tablet-L) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: $phone-L) {
    width: 150px;
    height: 150px;
  }
}
