@import "../../styles/colors.module";
@import "../../styles/breakpoints.module";

.cardWrapper {
  width: 33.333%;
  z-index: 2;
  position: relative;
  max-width: 470px;

  @media screen and (max-width: $laptop-S) {
    width: 50%;
  }

  @media screen and (max-width: $tablet-L) {
    width: 60%;
  }

  @media screen and (max-width: $tablet-S) {
    width: 70%;
  }

  @media screen and (max-width: $tablet-XS) {
    width: 95%;
  }

  @media screen and (max-width: $phone-M) {
    width: 100%;
  }

  .card {
    padding: 1.2rem;
    margin: 1.2rem;
    border: 1px solid $black;
    color: $white;
    border-radius: 10px;
    min-height: 350px;
    background: $gradient-card;

    @media screen and (max-width: $phone-L) {
      min-height: 300px;
    }

    @media screen and (max-width: $phone-S) {
      min-height: 350px;
    }
  }

  .imgWrapper {
    width: 100%;
    height: 170px;
    border-radius: 0.7rem;
    position: relative;

    @media screen and (max-width: $phone-M) {
      height: 130px;
    }

    @media screen and (max-width: $phone-S) {
      height: 100px;
    }

    img {
      width: 100%;
    }
  }

  .tags {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: 0;
    font-size: 1rem;

    @media screen and (max-width: $phone-L) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: $phone-S) {
      flex-wrap: wrap;
    }
  }

  .iconsWrapper {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    display: flex;
    gap: 0.7rem;

    @media screen and (max-width: $phone-S) {
      gap: 0.5rem;
      right: 0;
    }

    .github,
    .livePage {
      width: 1rem;
      height: 1rem;
      color: $white;
      cursor: pointer;
    }
  }

  .text {
    min-height: 180px;
    margin-top: 0.7rem;
    position: relative;

    @media screen and (max-width: $phone-L) {
      min-height: 150px;
    }

    @media screen and (max-width: $phone-S) {
      min-height: 250px;
    }

    .title {
      font-size: 1.2rem;

      @media screen and (max-width: $phone-S) {
        font-size: 1rem;
      }
    }
    .description {
      font-size: 0.9rem;
      margin: 0.7rem 0;

      @media screen and (max-width: $phone-L) {
        font-size: 0.8rem;
      }
    }
  }
}
