@import "../../styles/colors.module";
@import "../../styles/breakpoints.module";

.contact {
  padding: 8rem 0;
}

.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .contactText {
    color: $white;
    text-align: center;
    margin-bottom: 20px;

    p {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 2.5rem;

      @media screen and (max-width: $phone-L) {
        font-size: 2rem;
      }
    }
  }
}
.contactWrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  .contactOptionsContainer {
    display: flex;
    justify-content: center;
    gap: 4rem;
  }

  .contactForm {
    width: 70%;
    color: $white;
    max-width: 1600px;
    @media screen and (max-width: $phone-L) {
      width: 80%;
    }
    .title {
      font-size: 1.6rem;
      margin-bottom: 1.3rem;
      font-weight: 500;
    }
    .inputsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .input {
        width: 48.5%;
        margin: 0.7rem 0;
        @media screen and (max-width: $tablet-L) {
          width: 100%;
        }

        input {
          color: $white;
          -webkit-text-fill-color: #fff !important;
          -webkit-background-clip: text !important;
          background-clip: text !important;
        }
      }
    }

    .textArea {
      width: 100%;
      margin: 0.9rem 0;

      textarea {
        color: $white;
      }
    }
    .contactBtnLoading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    .contactBtn,
    .contactBtnLoading {
      color: $dark-blue;
      cursor: pointer;
      margin-top: 1rem;
      height: 45px;
      width: 174px;
      border: none;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      transition: 0.2s ease;
    }
    .contactBtn:hover,
    .contactBtn:active {
      background-color: $dark-blue;
      color:$white;
      border:$white 0.5px solid;
    }
  }
}
