$white: #fff;
$black: #000;
$social-btn: rgba(255, 255, 255, 0.153);
$dark-blue: #090939;
$nav-blue: radial-gradient(
  circle,
  rgba(2, 0, 36, 1) 0%,
  rgba(6, 6, 59, 1) 66%,
  rgba(9, 9, 57, 1) 73%
);
$gradient-card: linear-gradient(
  90deg,
  rgba(2, 0, 36, 1) 0%,
  rgba(6, 6, 79, 0.9697128851540616) 66%,
  rgba(6, 6, 94, 1) 73%
);
