@import "../../styles/colors.module";
@import "../../styles/breakpoints.module";

.desktopNavWrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 7px 10px 0px rgba(2, 14, 27, 0.988);
  background: $nav-blue;
}
