@import "../../styles/colors.module";
@import "../../styles/breakpoints.module";

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 200px 0;
  @media screen and (max-width: $laptop-XS) {
    width: 90%;
  }

  @media screen and (max-width: $tablet-XL) {
    flex-direction: column;
    gap: 2rem;
    padding: 100px 0;
    width: 95%;
  }

  .text {
    width: 60%;
    color: $white;

    @media screen and (max-width: $tablet-XL) {
      width: 90%;
    }

    .title {
      margin-bottom: 20px;
      font-size: 2.5rem;

      @media screen and (max-width: $tablet-XL) {
        font-size: 2rem;
      }
    }

    .desc {
      font-size: 1.5rem;

      @media screen and (max-width: $laptop-XS) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: $tablet-XL) {
        font-size: 1.1rem;
      }
    }
  }
  .image {
    width: 310px;
    height: 370px;
    position: relative;

    @media screen and (max-width: $tablet-XL) {
      width: 250px;
      height: 290px;
    }
    @media screen and (max-width: $phone-L) {
      width: 210px;
      height: 250px;
    }
    img {
      border-radius: 50%;
      object-fit: cover;
      z-index: 2;
    }
  }
}

.socials {
  display: flex;
  gap: 2rem;
  margin-top: 1.9rem;
  @media screen and (max-width: $phone-L) {
    flex-direction: column;
  }

  .socialsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 1px solid $white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 3px 10px;
    background-color: $social-btn;
    color: $white;
    text-decoration: none;
  }
}
